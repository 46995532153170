<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/wriststraps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/22.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  WRIST STRAP
                </h4>
                <h5 style="text-align: center">SINT-101A</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/wriststraps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/23.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ANTI-STATIC ADJUSTABLE ELASTIC WRIST STRAP
                </h4>
                <h5 style="text-align: center">SINT-103F</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/wriststraps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/24.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ADJUSTABLE ELASTIC WRIST STRAP
                </h4>
                <h5 style="text-align: center">SINT-104A/SINT-104B</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/wriststraps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/25.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ADJUSTABLE ELASTIC WRIST STRAP
                </h4>
                <h5 style="text-align: center">SINT-104G</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/wriststraps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/26.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ANTI-ALLERGENIC WRIST STRAP
                </h4>
                <h5 style="text-align: center">SINT-104E</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/wriststraps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/27.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ADJUSTABLE ELASTIC WRIST STRAP
                </h4>
                <h5 style="text-align: center">SINT-105A</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/wriststraps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/28.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  CLEAN ROOM WRIST STRAP
                </h4>
                <h5 style="text-align: center">SINT-107</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/wriststraps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/29.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  DUAL WRIST STRAP
                </h4>
                <h5 style="text-align: center">SINT-108</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/wriststraps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/30.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ADJUSTABLE METAL WRIST STRAP
                </h4>
                <h5 style="text-align: center">SINT-109</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/wriststraps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/31.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  FULLY ADJUSTABLE METAL WRIST STRAP
                </h4>
                <h5 style="text-align: center">SINT-110</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/wriststraps">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/32.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  DUAL METAL WRIST STRAP
                </h4>
                <h5 style="text-align: center">SINT-111</h5>
              </div>
            </div>
          </router-link>
        </div>
    
      </div>
    
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>